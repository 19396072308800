export const JobRoutes = {
    createJob: "/jobs/create",
    getJobs :"/jobs/",
    getJobDetails:"/jobs/details",
    updatejob:"/jobs/update",
    assignJob:"/jobs/assign",
    approveJob:"/jobs/approve",
    applyOnJob:"/jobs/applyOnJob",
    getAppliedJobs:"/jobs/getAppliedJobs"
  };
  