import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  FormControl,
  Box,
  Select,
  Typography,
  Button,
  MenuItem,
  TablePagination,
  Avatar,
  TextField,
  Grid,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RestoreIcon from "@mui/icons-material/Restore";
import { styled } from "@mui/system";

import Colors from "../../../../assets/styles";
import { Svgs } from "../../../../assets/images";
import { useNavigate } from "react-router-dom";
import PropertyServices from "../../../../api/PropertyServices/property.index";
import { ErrorHandler } from "../../../../utils/ErrorHandler";
import SimpleDialog from "../../../../components/Dialog/index";
import Loader from "../../../../components/Loader";

const tableHead = [
  "ID",
  "Property Name",
  "Management Company",
  "Address",
  "Contact Name",
  "Contact No",
  "Action",
];

const CustomSelect = styled(Select)({
  "&.MuiSelect-root": {
    border: "none",
    outline: "none",
    boxShadow: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSelect-icon": {
    color: "black",
  },
  "& .MuiSelect-select": {
    padding: "8px 32px 8px 8px",
  },
});

const App = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const sleep = () => new Promise((r) => setTimeout(r, 1000));

  const getProperties = async (
    searchParam = "",
    idParam = "",
    pageParam = 1,
    limitParam = 10,
    companyId = ""
  ) => {
    setLoading(true);
    try {
      await sleep();
      const { data } = await PropertyServices.getProperty(
        searchParam,
        idParam,
        pageParam,
        limitParam,
        companyId
      );
      setData(data.list);
      setCount(data.count);
    } catch (error) {
      ErrorHandler(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProperties(search, id, page + 1, limit, "");
  }, [page, limit,search]);

  const deleteProperty = async () => {
    try {
      const { responseCode } = await PropertyServices.deleteProperty(
        selectedPropertyId
      );
      if (responseCode === 200) {
        setOpenDialog(false);
        getProperties(search, id, page + 1, limit);
      }
    } catch (error) {
      ErrorHandler(error);
      console.log(error?.message);
    }
  };

  const handleSearch = () => {
    getProperties(search, id, 1, limit);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleReset = () => {
    setId("");
    setSearch("");
    getProperties("", "", 1, limit);
  };

  const filteredData = data.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Box sx={{ padding: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 8,
        }}
      >
        <Typography
          sx={{ fontSize: "26px", color: Colors.primary, fontWeight: "600" }}
        >
          Properties
        </Typography>
        <Button
          onClick={() => navigate("/properties/create")}
          variant="contained"
          color="primary"
          sx={{ mx: 1 }}
        >
          Add New
        </Button>
      </Box>
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "20px",
        }}
      >
        <Grid
          item
          display="flex"
          alignItems="center"
          sx={{ mt: 3, pl: 2 }}
          bgcolor={Colors.backgroundColor}
          border="1px solid rgba(10, 10, 10, 0.1)"
          borderRadius={2}
          boxShadow="0xp 0px 100px 0xp rgba(0,0,0,0.1)"
        >
          <FilterAltIcon />
          <Box
            sx={{
              width: "1px",
              height: "40px",
              backgroundColor: "lightgray",
              mx: 1,
            }}
          />
          <Box sx={{ px: 2, width: "100px" }}>Filter By</Box>

          <Box
            sx={{
              width: "1px",
              height: "40px",
              backgroundColor: "lightgray",
              mx: 1,
            }}
          />
          <FormControl sx={{ width: "100px", padding: "0px" }}>
            <TextField
              type="number"
              sx={{
                border: "none",
                outline: "none",
                boxShadow: "none",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
                input: { padding: "0px" },
                "& .MuiInputBase-input": {
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                },
              }}
              placeholder="ID"
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
          </FormControl>
          <Box
            sx={{
              width: "1px",
              height: "40px",
              backgroundColor: "lightgray",
              mx: 1,
            }}
          />
          <TextField
            placeholder="Search"
            InputProps={{
              sx: {
                input: {
                  paddingTop: "7px !important",
                  paddingBottom: "7px !important",
                  paddingRight: "50px !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              },
            }}
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <Box
            sx={{
              width: "1px",
              height: "40px",
              backgroundColor: "lightgray",
              mx: 1,
            }}
          />
          <Button
            sx={{ color: Colors.red, mx: 1 }}
            startIcon={<RestoreIcon />}
            onClick={handleReset}
          >
            Reset Filter
          </Button>
        </Grid>
        <Grid item>
          {/* <TextField
            sx={{ mt: "22px" }}
            placeholder="Search By Name"
            InputProps={{
              sx: {
                input: {
                  paddingTop: "7px !important",
                  paddingBottom: "7px !important",
                  paddingRight: "50px !important",
                },
              },
            }}
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          /> */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            sx={{ mt: 2.7, ml: 2 }}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      <TableContainer
        sx={{
          mt: 2,
          backgroundColor: Colors.backgroundColor,
          borderRadius: "10px",
        }}
        component={Paper}
      >
        <Table>
          <TableHead>
            <TableRow>
              {tableHead.map((column, index) => (
                <TableCell
                  key={index}
                  sx={{
                    fontWeight: "600",
                    color: Colors.black,
                    textAlign:
                      column === "Management Company" ? "left" : "center",
                  }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading ? (
            <TableRow>
              <TableCell colSpan={tableHead.length}>
                <Loader width="40px" height="40px" />
              </TableCell>
            </TableRow>
          ) : (
            filteredData.map((row) => (
              <TableBody key={row._id}>
                <TableRow
                  onClick={() =>
                    navigate("/properties/details", { state: row })
                  }
                >
                  <TableCell sx={{ textAlign: "center" }}>
                    {row.num_id}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>{row.name}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Avatar src={`${baseUrl}${row.company.logo}`} />
                      {row.company.name}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {row.map_address ?row.map_address :"-"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {row.cp_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {row.cp_phone}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <Box
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("/properties/update", { state: row });
                        }}
                        dangerouslySetInnerHTML={{ __html: Svgs["edit"] }}
                      />
                      <Box
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenDialog(true);
                          setSelectedPropertyId(row._id);
                        }}
                        dangerouslySetInnerHTML={{ __html: Svgs["delete"] }}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))
          )}
        </Table>
      </TableContainer>
      <SimpleDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        border={`4px solid ${Colors.primary}`}
        title="Are You Sure you want to Delete?"
      >
        <Box
          sx={{ display: "flex", justifyContent: "center", gap: "16px", mt: 5 }}
        >
          <Box
            onClick={() => setOpenDialog(false)}
            sx={{
              background: Colors.black,
              color: Colors.primary,
              borderRadius: "10px",
              px: 5,
              py: 2,
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            No, Cancel
          </Box>
          <Box
            sx={{
              color: Colors.black,
              background: Colors.primary,
              borderRadius: "10px",
              px: 5,
              py: 2,
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={deleteProperty}
          >
            Yes, Confirm
          </Box>
        </Box>
      </SimpleDialog>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ color: "text.secondary", ml: 2 }}>
          {`Showing ${page * limit + 1}-${Math.min(
            (page + 1) * limit,
            count
          )} of ${count}`}
        </Typography>
        <Box>
          <TablePagination
            component={Paper}
            sx={{
              borderBottom: "none",
              border: "1px solid black",
              bgcolor: "transparent",
              ".MuiTablePagination-toolbar": {
                paddingLeft: 0,
              },
              ".MuiTablePagination-spacer": {
                flex: "none",
              },
              ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                {
                  display: "none",
                },
              ".MuiTablePagination-actions": {
                marginLeft: 0,
              },
              ".MuiTablePagination-actions button:first-child": {
                borderRadius: 0,
                p: 0,
                pr: "8px",
                borderRight: "1px solid black",
              },
              ".css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions":
                {
                  marginLeft: "14px !important",
                },
              ".MuiTablePagination-toolbar": {
                minHeight: "14px !important",
                padding: "0px !important",
              },
            }}
            rowsPerPageOptions={[]} // Removes rows per page selector
            count={count}
            rowsPerPage={limit}
            page={page}
            onPageChange={handlePageChange}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default App;
