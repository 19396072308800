import {
    Box,
    Typography,
    Card,
    CardContent,
    Tabs,
    Tab,
    Avatar,
    Button,
    Grid,
    Divider,
    InputLabel,
    IconButton,
    Tooltip,
    Link,
    CardMedia,
    FormControl,
    FormHelperText,
    TextField,
  } from "@mui/material";
  import React, { useEffect, useRef, useState } from "react";
  import Colors from "../../../../assets/styles";
  import { useNavigate, useParams } from "react-router-dom";
  import EmployeeServices from "../../../../api/EmployeeServices/employee.index";
  import { ErrorHandler } from "../../../../utils/ErrorHandler";
  import moment from "moment";
  import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
  import DescriptionIcon from "@mui/icons-material/Description";
  import { Images } from "../../../../assets/images";
  import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
  import ModeEditIcon from "@mui/icons-material/ModeEdit";
  import { PDFExport } from "@progress/kendo-react-pdf";
  import SimpleDialog from "../../../../components/Dialog";
  import PhoneInput from "react-phone-input-2";
  import "react-phone-input-2/lib/style.css";
  import { Controller, useForm } from "react-hook-form";
  import {
    ErrorToaster,
    SuccessToaster,
  } from "../../../../components/Toaster/index";
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  export default function EmployeeDetails() {
    const [value, setValue] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openMessageDialog, setOpenMessageDialog] = useState(false);
    const [groupedSkills, setGroupedSkills] = useState({});
    const contentRef = useRef(null);
  
    const paramId = useParams();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();
  
    const {
      register,
      handleSubmit,
      formState: { errors },
      control: control1,
    } = useForm();
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const getEmployeesDetails = async () => {
      setLoading(true);
      try {
        const { data } = await EmployeeServices.getEmployeeRequestDetail(paramId.id);
        setData(data.details);
  
        const skillData = data.details.flatMap(
          (detail) => detail.skill_details || []
        );
        console.log(skillData);
  
        if (skillData.length > 0) {
          let skills = skillData.reduce((acc, item) => {
            if (!acc[item.category]) {
              acc[item.category] = [];
            }
            acc[item.category].push(item.skill);
            return acc;
          }, {});
  
          setGroupedSkills(skills);
        } else {
          setGroupedSkills({});
        }
        // setCount(data.count);
        // navigate("/companies");
        // setLoading(false);
      } catch (error) {
        ErrorHandler(error);
        console.log(error?.message);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      getEmployeesDetails();
    }, []);
    const handleExportWithComponent = (pdfExportComponent) => {
      pdfExportComponent.current.save();
    };
  
    // const sendMessage = async (FormData) => {
    //   const obj = {
    //     phone: data?.[0]?.user_info?.phone || "",
    //     message: FormData?.message,
    //   };
    //   try {
    //     const response = await EmployeeServices.SendMessage(obj);
    //     SuccessToaster(response.message);
    //     setOpenMessageDialog(false)
    //   } catch (error) {
    //     ErrorHandler(error);
    //     ErrorToaster(error);
    //   }
    //   console.log(obj);
    // };
  
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            alignItems: "center",
            mt: 10,
            pl: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "26px",
                color: "rgb(148 149 153)",
                fontWeight: "600",
              }}
            >
              Employees /
            </Typography>
  
            <Typography
              sx={{ fontSize: "22px", color: Colors.primary, fontWeight: "600" }}
            >
              Employee Detail
            </Typography>
          </Box>
          <Box
            sx={{
              border: `2px solid ${Colors.red}`,
              color: Colors.red,
              px: 3,
              py: 0.6,
              mr: 2,
              fontSize: "15px",
              fontWeight: "600",
              cursor: "pointer",
            }}
            onClick={() => handleExportWithComponent(contentRef)}
          >
            Export PDF
          </Box>
        </Box>
        <Card
          sx={{
            mt: 3,
            ml: 2,
            mr: 2,
            backgroundColor: Colors.backgroundColor,
            borderRadius: "10px",
            boxShadow: "0px 0px 100px 0px rgb(0,0,0,0.1)",
          }}
        >
          <CardContent>
            {data?.map((item) => (
              <Grid container spacing={2}>
                <Grid item md={5}>
                  <Box display="flex" gap="20px">
                    <Avatar
                      src={item?.picture ? baseUrl + item.picture : ""}
                      alt="Maria Johnson"
                      sx={{
                        width: 100,
                        height: 100,
                        border: "1px solid #d2cece",
                        img: { objectFit: "contain !important" },
                      }}
                    />
                    <Box>
                      <Typography
                        sx={{ fontSize: "24px", color: Colors.primary }}
                      >
                        {item.first_name + " " + item.last_name}
                      </Typography>
                      <Typography sx={{ fontSize: "12px" }}>
                        {item.desired_position}
                      </Typography>
  
                      <Box sx={{ mt: 3 }}>
                        <Typography sx={{ fontSize: "12px" }}>
                          Employee Id: {item.num_id}
                        </Typography>
                        <Typography sx={{ fontSize: "12px", pt: 0.5 }}>
                          Date Added :{" "}
                          {moment(item.created_at).format("DD-MM-YYYY")}
                        </Typography>
                      </Box>
                      <Box>
                        {/* <Box
                          sx={{ mt: 1.5 }}
                          onClick={() => setOpenMessageDialog(true)}
                        >
                          <Button
                            sx={{
                              background: Colors.primary,
                              ":hover": { background: Colors.primary },
                              color: Colors.white,
                            }}
                          >
                            Send a message
                          </Button>
                        </Box> */}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
  
                <Grid item md={1}>
                  <Divider
                    sx={{ display: "flex", float: "left" }}
                    orientation="vertical"
                  />
                </Grid>
                <Grid item md={5} sx={{ mt: 2 }}>
                  <Box>
                    <Typography variant="body2">
                      Phone:
                      <span style={{ color: Colors.primary }}>
                        {" "}
                        {item?.phone}
                      </span>
                    </Typography>
                    <Typography sx={{ pt: 1 }} variant="body2">
                      Email:{" "}
                      <span style={{ color: Colors.primary }}>
                        {item?.email}
                      </span>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" sx={{ pt: 1 }}>
                      Area: {item?.area_town}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" sx={{ pt: 1 }}>
                      Age: {item?.age + " year"}
                    </Typography>
                  </Box>
                </Grid>
                {/* <Grid item md={1}>
                  <IconButton
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate(`/employees/update/${item._id}`)}
                  >
                    <ModeEditIcon />
                  </IconButton>
                </Grid> */}
              </Grid>
            ))}
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="profile tabs"
              sx={{ mt: 3, marginBottom: "-24px" }}
            >
              <Tab label="Skills and Experience" />
              <Tab label="Job Preference" />
              <Tab label="Personal Documents" />
              <Tab label="Additional Details" />
            </Tabs>
          </CardContent>
        </Card>
        <Card
          sx={{
            mt: 3,
            ml: 2,
            mr: 2,
  
            backgroundColor: Colors.backgroundColor,
            borderRadius: "10px",
            boxShadow: "0px 0px 100px 0px rgb(0,0,0,0.1)",
          }}
        >
          {data?.map((item) => (
            <>
              <CardContent sx={{ pb: "0 !important" }}>
                <TabPanel value={value} index={0}>
                  <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                    <Box sx={{ width: "150px" }}>Experience</Box>
                    <Box sx={{ fontWeight: 600 }}>
                      {item?.experience ? item?.experience : "0"} Year
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                    <Box sx={{ width: "150px" }}>Desired Position</Box>
                    <Box sx={{ fontWeight: 600 }}>{item?.desired_position}</Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                    <Box sx={{ width: "150px" }}>Skill</Box>
                    <Box sx={{ fontWeight: 600 }}>{item?.skill_type}</Box>
                  </Box>
                  {Object.keys(groupedSkills).map((category) => (
                    <Box
                      sx={{ display: "flex", gap: "15px", pt: 0.6 }}
                      key={category}
                    >
                      <Box sx={{ width: "150px" }}>{category}</Box>
                      <Box sx={{ fontWeight: 600 }}>
                        {groupedSkills[category].join(" , ")}
                      </Box>
                    </Box>
                  ))}
                </TabPanel>
  
                <TabPanel value={value} index={1}>
                  <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                    <Box sx={{ width: "200px" }}>Area Town Preferred</Box>
                    <Box sx={{ fontWeight: 600 }}>{item?.area_town} </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                    <Box sx={{ width: "200px" }}>Job Type Preference</Box>
                    <Box sx={{ fontWeight: 600 }}>
                      {item?.job_type ? item?.job_type : "-"}{" "}
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                    <Box sx={{ width: "200px" }}>Experience</Box>
                    <Box sx={{ fontWeight: 600 }}>{item?.experience} </Box>
                  </Box>
                  {/* <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                    <Box sx={{ width: "200px" }}>Property Type</Box>
                    <Box sx={{ fontWeight: 600 }}>
                      {item?.property_types.join(" , ")}{" "}
                    </Box>
                  </Box> */}
  
                  <Box sx={{ color: Colors.primary, pt: 0.8 }}>
                    Specifications for Temporary Work
                  </Box>
                  <Grid container>
                    <Grid item md={7}>
                      <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                        <Box sx={{ width: "200px" }}>Hours Available</Box>
                        <Box sx={{ fontWeight: 600 }}>
                          {item?.temporary_work?.hours_from +
                            " - " +
                            item?.temporary_work?.hours_to}{" "}
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                        <Box sx={{ width: "200px" }}>
                          Work on same day assignment required?
                        </Box>
                        <Box sx={{ fontWeight: 600 }}>
                          {item?.temporary_work?.same_day_assignment == false
                            ? "No"
                            : "Yes"}{" "}
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                        <Box sx={{ width: "200px" }}>
                          On Call Availibility required after hours/weekends?
                        </Box>
                        <Box sx={{ fontWeight: 600 }}>
                          {item?.temporary_work?.after_hours == false
                            ? "No"
                            : "Yes"}{" "}
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                        <Box sx={{ width: "200px" }}>
                          Work on Weekends Required?
                        </Box>
                        <Box sx={{ fontWeight: 600 }}>
                          {item?.temporary_work?.work_weekends == false
                            ? "No"
                            : "Yes"}{" "}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={7}>
                      <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                        <Box sx={{ width: "200px" }}>Own Transport required?</Box>
                        <Box sx={{ fontWeight: 600 }}>
                          {item?.transportation == false ? "No" : "Yes"}{" "}
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                        <Box sx={{ width: "200px" }}>Travelling Required?</Box>
                        <Box sx={{ fontWeight: 600 }}>
                          {item?.travel == false ? "No" : "Yes"}{" "}
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                        <Box sx={{ width: "200px" }}>
                          Working on Braunfels Required?
                        </Box>
                        <Box sx={{ fontWeight: 600 }}>
                          {item?.new_branfels == false ? "No" : "Yes"}{" "}
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                        <Box sx={{ width: "200px" }}>
                          Working on Boerne Required?
                        </Box>
                        <Box sx={{ fontWeight: 600 }}>
                          {item?.boerne == false ? "No" : "Yes"}{" "}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ color: Colors.primary, pt: 0.6 }}>
                    Specifications for Direct Hire
                  </Box>
                  <Grid container>
                    <Grid item md={5}>
                      <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                        <Box sx={{ width: "200px" }}>Salary Offered</Box>
                        <Box sx={{ fontWeight: 600 }}>
                          {item?.direct_hire?.desired_salary + " " + "$"}
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                        <Box sx={{ width: "200px" }}>
                          On Call Availibility required after hours/weekends?
                        </Box>
                        <Box sx={{ fontWeight: 600 }}>
                          {item?.direct_hire?.after_hours == false ? "No" : "Yes"}{" "}
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                        <Box sx={{ width: "200px" }}>
                          Work on Weekends Required?
                        </Box>
                        <Box sx={{ fontWeight: 600 }}>
                          {item?.direct_hire?.work_weekends == false
                            ? "No"
                            : "Yes"}{" "}
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                        <Box sx={{ width: "200px" }}>
                          Insurance Benefits Provided?
                        </Box>
                        <Box sx={{ fontWeight: 600 }}>
                          {item?.direct_hire?.insurance_mandatory == false
                            ? "No"
                            : "Yes"}{" "}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={5}>
                      {/* <Box sx={{ display: "flex", gap: "15px", pt: 0.8 }}>
                        <Box sx={{ width: "200px" }}>Minimum Salary</Box>
                        <Box sx={{ fontWeight: 600 }}>
                          {item?.minimum_salary + " " + "$"}{" "}
                        </Box>
                      </Box> */}
                      <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                        <Box sx={{ width: "200px" }}>
                          Required to Live on Site?
                        </Box>
                        <Box sx={{ fontWeight: 600 }}>
                          {item?.live_on_site == false ? "No" : "Yes"}{" "}
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                        <Box sx={{ width: "200px" }}>Relocation Required?</Box>
                        <Box sx={{ fontWeight: 600 }}>
                          {item?.relocate == false ? "No" : "Yes"}{" "}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </TabPanel>
  
                <TabPanel value={value} index={2}>
                  {item.documents.length > 0 && (
                    <Box>
                      <Grid container>
                        {item.documents.map((imagePath, i) => {
                          if (imagePath.toLowerCase().includes(".pdf")) {
                            return (
                              <Grid item xs={12} sm={6} md={3} key={i}>
                                <Box
                                  sx={{
                                    position: "relative",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <img
                                    src={Images.pdfLogo}
                                    alt="prescriptionImg"
                                    style={{
                                      width: "100px",
                                      height: "150px",
                                      objectFit: "contain",
                                    }}
                                  />
                                  <Box
                                    sx={{
                                      width: "200px", // Same width as the image
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        overflowWrap: "break-word",
                                        wordWrap: "break-word",
                                        wordBreak: "break-word",
                                        hyphens: "auto",
                                        maxWidth: "100%", // Ensure it doesn't overflow the container
                                      }}
                                    >
                                      {imagePath.split("_").slice(1).join("_")}
                                    </Typography>
                                  </Box>
                                  <Tooltip title="download">
                                    <Link
                                      sx={{
                                        boxShadow:
                                          "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;",
                                        cursor: "pointer",
                                        bgcolor: "#ffffffc9",
                                        position: "absolute",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: 40,
                                        height: 40,
                                        borderRadius: 40,
                                        top: 10,
                                        "&:hover": { bgcolor: Colors.cloud },
                                      }}
                                      onClick={() => {
                                        const url = baseUrl + imagePath;
                                        const link = document.createElement("a");
                                        link.href = url;
                                        link.target = "_blank"; // Open the link in a new tab
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                      }}
                                    >
                                      <SystemUpdateAltIcon
                                        sx={{
                                          fontSize: "24px",
                                          color: Colors.primary,
                                        }}
                                      />
                                    </Link>
                                  </Tooltip>
                                </Box>
                              </Grid>
                            );
                          } else if (imagePath.toLowerCase().includes(".docx")) {
                            return (
                              <Grid item xs={12} sm={6} md={3} key={i}>
                                <Box
                                  sx={{
                                    position: "relative",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <img
                                    src={Images.wordIcon}
                                    alt="prescriptionImg"
                                    style={{
                                      width: "100px",
                                      height: "150px",
                                      objectFit: "contain",
                                    }}
                                  />
                                  <Box
                                    sx={{
                                      width: "200px", // Same width as the image
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        overflowWrap: "break-word",
                                        wordWrap: "break-word",
                                        wordBreak: "break-word",
                                        hyphens: "auto",
                                        maxWidth: "100%", // Ensure it doesn't overflow the container
                                      }}
                                    >
                                      {imagePath.split("_").slice(1).join("_")}
                                    </Typography>
                                  </Box>
                                  <Tooltip title="download">
                                    <Link
                                      sx={{
                                        boxShadow:
                                          "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;",
                                        cursor: "pointer",
                                        bgcolor: "#ffffffc9",
                                        position: "absolute",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: 40,
                                        height: 40,
                                        borderRadius: 40,
                                        top: 10,
                                        "&:hover": { bgcolor: Colors.cloud },
                                      }}
                                      onClick={() => {
                                        const url = baseUrl + imagePath;
                                        const link = document.createElement("a");
                                        link.href = url;
                                        link.target = "_blank"; // Open the link in a new tab
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                      }}
                                    >
                                      <SystemUpdateAltIcon
                                        sx={{
                                          fontSize: "24px",
                                          color: Colors.primary,
                                        }}
                                      />
                                    </Link>
                                  </Tooltip>
                                </Box>
                              </Grid>
                            );
                          }else if ( imagePath.toLowerCase().includes(".png") ||  imagePath.toLowerCase().includes(".jpg") ||  imagePath.toLowerCase().includes(".jpeg")) {
                            return (
                              <Grid item xs={12} sm={6} md={3} key={i}>
                                <Box
                                  sx={{
                                    position: "relative",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <img
                                    src={Images.picIcon}
                                    alt="prescriptionImg"
                                    style={{
                                      width: "100px",
                                      height: "150px",
                                      objectFit: "contain",
                                    }}
                                  />
                                  <Box
                                    sx={{
                                      width: "200px", // Same width as the image
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        overflowWrap: "break-word",
                                        wordWrap: "break-word",
                                        wordBreak: "break-word",
                                        hyphens: "auto",
                                        maxWidth: "100%", // Ensure it doesn't overflow the container
                                      }}
                                    >
                                      {imagePath.split("_").slice(1).join("_")}
                                    </Typography>
                                  </Box>
                                  <Tooltip title="download">
                                    <Link
                                      sx={{
                                        boxShadow:
                                          "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;",
                                        cursor: "pointer",
                                        bgcolor: "#ffffffc9",
                                        position: "absolute",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: 40,
                                        height: 40,
                                        borderRadius: 40,
                                        top: 10,
                                        "&:hover": { bgcolor: Colors.cloud },
                                      }}
                                      onClick={() => {
                                        const url = baseUrl + imagePath;
                                        const link = document.createElement("a");
                                        link.href = url;
                                        link.target = "_blank"; // Open the link in a new tab
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                      }}
                                    >
                                      <SystemUpdateAltIcon
                                        sx={{
                                          fontSize: "24px",
                                          color: Colors.primary,
                                        }}
                                      />
                                    </Link>
                                  </Tooltip>
                                </Box>
                              </Grid>
                            );
                          }
                        })}
                      </Grid>
                      {/* Display additional information or actions related to the uploaded files */}
                    </Box>
                  )}
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                    <Box sx={{ width: "30%" }}>Language</Box>
                    <Box sx={{ fontWeight: 600, width: "70%" }}>
                      {item?.language}{" "}
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                    <Box sx={{ width: "30%" }}>Driving License</Box>
                    <Box sx={{ fontWeight: 600, width: "70%" }}>
                      {item?.driving_license == false ? "No" : "Yes"}{" "}
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                    <Box sx={{ width: "30%" }}>Restrictions</Box>
                    <Box sx={{ fontWeight: 600, width: "70%" }}>
                      {" "}
                      {item?.properties
                        ?.map((property) => property.name)
                        .join(" , ")}
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                    <Box sx={{ width: "30%" }}>Recreational Drugs</Box>
                    <Box sx={{ fontWeight: 600, width: "70%" }}>
                      {item?.recreational_drugs?.map((drug) => drug).join(" , ")}
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: "15px", pt: 0.6 }}>
                    <Box sx={{ width: "30%" }}>Additional Notes</Box>
                    <Box sx={{ fontWeight: 600, width: "70%" }}>
                      {item?.additional_notes}{" "}
                    </Box>
                  </Box>
                </TabPanel>
              </CardContent>
            </>
          ))}
        </Card>
  
        {/* {openMessageDialog && (
          <>
            <SimpleDialog
              open={openMessageDialog}
              onClose={() => setOpenMessageDialog(false)}
              border={`4px solid ${Colors.primary}`}
              title="Send A Message"
            >
              <form onSubmit={handleSubmit(sendMessage)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <InputLabel sx={{ fontWeight: "bold", color: Colors.black }}>
                      Message
                    </InputLabel>
                    <TextField
                      multiline
                      minRows={4}
                      fullWidth
                      variant="outlined"
                      placeholder="Type your message here..."
                      {...register("message", {
                        required: "Message is required",
                      })}
                      error={!!errors.message}
                      helperText={errors.message ? errors.message.message : ""}
                      sx={{ mt: 1 }}
                    />
                  </Grid>
  
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                  >
                    <Button
                      sx={{
                        color: Colors.black,
                        mr: 2,
                        border: "1px solid black",
                        px: 4,
                      }}
                      onClick={() => setOpenMessageDialog(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      sx={{ px: 5 }}
                      variant="contained"
                      color="primary"
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </SimpleDialog>
          </>
        )}
   */}
        <Box>
          <PDFExport
            ref={contentRef}
            landscape={false}
            paperSize="A4"
            margin={0}
            fileName="Employee Detail"
            pageTemplate={({ pageNumber, totalPages }) => (
              <>
                {/* Header */}
                <Box
                  style={{
                    position: "absolute",
                    top: 0,
                    width: "100%",
                    textAlign: "center",
                    padding: "10px",
                  }}
                >
                  <img
                    src={Images.Header}
                    style={{ width: "100%" }}
                    alt="Header"
                  />
                </Box>
  
                {/* Footer */}
                <Box
                  style={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    textAlign: "center",
                    padding: "10px",
                  }}
                >
                  <img
                    src={Images.Footer}
                    style={{ width: "100%" }}
                    alt="Footer"
                  />
                </Box>
              </>
            )}
          >
            <Box
              id="pdf-sec"
              style={{
                padding: "20px",
                paddingTop: "120px",
                paddingBottom: "60px",
              }}
            >
              <Box
                id="pdf-sec"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: Colors.primary,
                  textAlign: "center",
                  margin: "0",
                  marginBottom: "20px",
                }}
              >
                EMPLOYEE DETAIL
              </Box>
              <Grid container spacing={2} sx={{ pl: 4 }}>
                {data?.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Grid container>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Name
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item.first_name + " " + item.last_name}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Employee Id
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.num_id}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Desired Position
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.desired_position}
                        </Grid>
                      </Grid>
  
                      {/* Additional Fields */}
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Phone
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.phone}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Area
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.area_town}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Email
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.email || "-"}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Age
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.age + " year"}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          fontWeight: "bold",
                          color: Colors.primary,
                          marginTop: "10px",
                        }}
                      >
                        Skills And Experience
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Experience
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.experience}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Desired Position
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.desired_position || "-"}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Skill
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.skill_type || "N/A"}
                        </Grid>
                      </Grid>
                      {Object.keys(groupedSkills).map((category) => (
                        <Grid item xs={12} container key={category}>
                          <Grid item xs={6} style={{ fontWeight: 500 }}>
                            {category}
                          </Grid>
                          <Grid item xs={6} style={{ fontWeight: 500 }}>
                            {groupedSkills[category].join(" , ")}
                          </Grid>
                        </Grid>
                      ))}
                      <Grid
                        item
                        xs={12}
                        style={{
                          fontWeight: "bold",
                          color: Colors.primary,
                          marginTop: "10px",
                        }}
                      >
                        Job Preference
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Job Type Preference
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.job_type}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Hours Available
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.temporary_work?.hours_from} -{" "}
                          {item?.temporary_work?.hours_to}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Work on Same Day Assignment Required?
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.temporary_work?.same_day_assignment
                            ? "Yes"
                            : "No"}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          On Call Availability required after hours/weekends?
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.temporary_work?.after_hours ? "Yes" : "No"}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Work on Weekends Required?
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.temporary_work?.work_weekends ? "Yes" : "No"}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Own Transport Required?
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.transportation ? "Yes" : "No"}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Travelling Required?
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.travel ? "Yes" : "No"}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Working on Braunfels Required?
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.new_branfels ? "Yes" : "No"}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Working on Boerne Required?
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.boerne ? "Yes" : "No"}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Salary Offered
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          ${item?.direct_hire?.desired_salary} 
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          On Call Availability required after hours/weekends?
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.direct_hire?.after_hours ? "Yes" : "No"}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Work on Weekends Required?
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.direct_hire?.work_weekends ? "Yes" : "No"}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Insurance Benefits Provided?
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.direct_hire?.insurance_mandatory ? "Yes" : "No"}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Required to Live on Site?
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.live_on_site ? "Yes" : "No"}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Relocation Required?
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.relocate ? "Yes" : "No"}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          fontWeight: "bold",
                          color: Colors.primary,
                          marginTop: "10px",
                        }}
                      >
                        Additional Details
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Language
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.language}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Driving License
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.driving_license ? "Yes" : "No"}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Restrictions
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.properties
                            ?.map((property) => property.name)
                            .join(" , ")}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Recreational Drugs
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.recreational_drugs
                            ?.map((drug) => drug)
                            .join(" , ")}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          Additional Notes
                        </Grid>
                        <Grid item xs={6} style={{ fontWeight: 500 }}>
                          {item?.additional_notes || "-"}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </PDFExport>
        </Box>
      </>
    );
  }
  