import {
  Box,
  Typography,
  Card,
  CardContent,
  Tabs,
  Tab,
  Avatar,
  Button,
  Grid,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  TablePagination,
  IconButton,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Colors from "../../../../assets/styles";
import { Images, Svgs } from "../../../../assets/images";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { PDFExport } from "@progress/kendo-react-pdf";

export default function PropertyDetails() {
  const [value, setValue] = useState(0);
  const { state } = useLocation();
  const navigate = useNavigate();
  const contentRef = useRef(null);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  console.log(baseUrl);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleExportWithComponent = (pdfExportComponent) => {
    pdfExportComponent.current.save();
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          alignItems: "center",
          mt: 10,
          pl: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "26px",
              color: "rgb(148 149 153)",
              fontWeight: "600",
            }}
          >
            Properties /
          </Typography>

          <Typography
            sx={{ fontSize: "22px", color: Colors.primary, fontWeight: "600" }}
          >
            Property Detail
          </Typography>
        </Box>
        <Box
          sx={{
            border: `2px solid ${Colors.red}`,
            color: Colors.red,
            px: 3,
            py: 0.6,
            mr: 2,
            fontSize: "15px",
            fontWeight: "600",
            cursor: "pointer",
          }}
          onClick={() => handleExportWithComponent(contentRef)}
        >
          Export PDF
        </Box>
      </Box>

      <Card
        sx={{
          mt: 3,
          ml: 2,
          mr: 2,
          backgroundColor: Colors.backgroundColor,
          borderRadius: "10px",
          boxShadow: "0px 0px 100px 0px rgb(0,0,0,0.1)",
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Box display="flex" gap="25px" justifyContent={"space-between"}>
                <Box display="flex" gap="25px">
                  <Avatar
                    alt="Maria Johnson"
                    src=""
                    sx={{ width: 100, height: 100 }}
                  />
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "24px",
                        color: Colors.primary,
                        textTransform: "capitalize",
                      }}
                    >
                      {state?.name}
                    </Typography>
                    <Typography sx={{ fontSize: "12px" }}>
                      {state?.map_address}
                    </Typography>
                  </Box>
                </Box>
                <IconButton
                  sx={{
                    mt: -8,
                    ":hover": {
                      background: "none !important",
                    },
                  }}
                  onClick={() =>
                    navigate("/properties/update", { state: state })
                  }
                >
                  <ModeEditIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card
        sx={{
          mt: 3,
          ml: 2,
          mr: 2,
          backgroundColor: Colors.backgroundColor,
          borderRadius: "10px",
          boxShadow: "0px 0px 100px 0px rgb(0,0,0,0.1)",
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ fontWeight: "bold", fontSize: "20px" }}>
                  Management Company
                </Box>
                <IconButton
                  sx={{
                    ":hover": {
                      background: "none !important",
                    },
                  }}
                  onClick={() =>
                    navigate("/companies/update", { state: state.company })
                  }
                >
                  <ModeEditIcon />
                </IconButton>
              </Box>
              <Box display="flex" gap="25px" marginTop="15px">
                <Avatar
                  alt="Maria Johnson"
                  src={baseUrl + state?.company.logo}
                  sx={{ width: 100, height: 100 }}
                />
                <Box>
                  <Typography sx={{ fontSize: "24px", color: Colors.primary }}>
                    {state?.company.name}
                  </Typography>
                  {/* <Typography sx={{ fontSize: "12px" }}>
                    22 Ave Street,NewYork USA
                  </Typography> */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              mt: 3,
              ml: 2,
              mr: 2,

              backgroundColor: Colors.backgroundColor,
              borderRadius: "10px",
              boxShadow: "0px 0px 100px 0px rgb(0,0,0,0.1)",
            }}
          >
            <CardContent>
              <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ fontWeight: "bold", fontSize: "18px" }}>
                    Basic Information
                  </Box>
                  <IconButton
                    sx={{
                      // mt: -8,
                      ":hover": {
                        background: "none !important",
                      },
                    }}
                    onClick={() =>
                      navigate("/properties/update", { state: state })
                    }
                  >
                    <ModeEditIcon />
                  </IconButton>
                </Box>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <Box>Phone</Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Box >{state?.phone}</Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box>Management Company</Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Box >
                      {state?.company.name}
                    </Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box>Date Added</Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Box>{moment(state?.created_at).format("DD-MM-YYYY")}</Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box>Address</Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Box >{state?.map_address}</Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box>Additional Notes</Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Box>{state?.notes ? state.notes : "-"}</Box>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              mt: 3,
              ml: 2,
              mr: 2,

              backgroundColor: Colors.backgroundColor,
              borderRadius: "10px",
              boxShadow: "0px 0px 100px 0px rgb(0,0,0,0.1)",
            }}
          >
            <CardContent>
              <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ fontWeight: "bold", fontSize: "18px" }}>
                    Other Information
                  </Box>
                  <IconButton
                    sx={{
                      // mt: -8,
                      ":hover": {
                        background: "none !important",
                      },
                    }}
                    onClick={() =>
                      navigate("/properties/update", { state: state })
                    }
                  >
                    <ModeEditIcon />
                  </IconButton>
                </Box>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <Box>Zip Code</Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Box>{state?.zip_code}</Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box>Area</Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Box>{state?.area}</Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box>Contact Person</Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Box>{state?.cp_name}</Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box>Contact Person Number</Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Box>{state?.cp_phone}</Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box>Billing Preference</Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Box>{state?.billing_preference}</Box>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box>
        <PDFExport
          ref={contentRef}
          landscape={false}
          paperSize="A4"
          margin={0}
          fileName="Property Detail"
          pageTemplate={({ pageNumber, totalPages }) => (
            <>
              {/* Header */}
              <Box
                style={{
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                <img
                  src={Images.Header}
                  style={{ width: "100%" }}
                  alt="Header"
                />
              </Box>

              {/* Footer */}
              <Box
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                <img
                  src={Images.Footer}
                  style={{ width: "100%" }}
                  alt="Footer"
                />
              </Box>
            </>
          )}
        >
          <Box
            className="pdf-sec"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: Colors.primary,
              textAlign: "center",
              margin: "0",
              paddingTop: "120px",
            }}
          >
            PROPERTY DETAIL
          </Box>
          <Grid
            className="pdf-sec"
            container
            spacing={2}
            sx={{ mt: 3, ml: 4, mr: 2 }}
          >
            {/* <Box display="flex" gap="10px" sx={{ mt: 2, ml: 2 }}>
              <Box>
                <Typography sx={{ fontSize: "12px" }}>Propety Name:</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "12px" }}>
                  {state?.name}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" gap="10px" sx={{ ml: 2 }}>
              <Box>
                <Typography sx={{ fontSize: "12px" }}>
                  Management Company:
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "12px" }}>
                  {state?.company.name}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" gap="10px" sx={{ ml: 2 }}>
              <Box>
                <Typography sx={{ fontSize: "12px" }}>
                  Property Address:
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "12px" }}>
                  {state?.address}
                </Typography>
              </Box>
            </Box> */}

           

            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: Colors.primary,
                  }}
                >
                  Basic Information
                </Box>
              </Box>
              <Grid container>
                <Grid item xs={5}>
                  <Box>Property Name</Box>
                </Grid>
                <Grid item xs={7}>
                  <Box >{state?.name}</Box>
                </Grid>
                <Grid item xs={5}>
                  <Box>Phone</Box>
                </Grid>
                <Grid item xs={7}>
                  <Box >{state?.phone}</Box>
                </Grid>

                <Grid item xs={5}>
                  <Box>Management Company</Box>
                </Grid>
                <Grid item xs={7}>
                  <Box >
                    {state?.company.name}
                  </Box>
                </Grid>

                <Grid item xs={5}>
                  <Box>Date Added</Box>
                </Grid>
                <Grid item xs={7}>
                  <Box>{moment(state?.created_at).format("DD-MM-YYYY")}</Box>
                </Grid>

                <Grid item xs={5}>
                  <Box>Address</Box>
                </Grid>
                <Grid item xs={7}>
                  <Box >{state?.map_address}</Box>
                </Grid>

                <Grid item xs={5}>
                  <Box>Additional Notes</Box>
                </Grid>
                <Grid item xs={7}>
                  <Box>{state?.notes ? state.notes : "-"}</Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: Colors.primary,
                    }}
                  >
                    Other Information
                  </Box>
                </Box>
                <Grid container>
                  <Grid item xs={5}>
                    <Box>Zip Code</Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Box>{state?.zip_code}</Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box>Area</Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Box>{state?.area}</Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box>Contact Person</Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Box>{state?.cp_name}</Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box>Contact Person Number</Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Box>{state?.cp_phone}</Box>
                  </Grid>

                  <Grid item xs={5}>
                    <Box>Billing Preference</Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Box>{state?.billing_preference}</Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </PDFExport>
      </Box>
    </>
  );
}
